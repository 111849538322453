import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: 'hu',
  fallbackLocale: 'en',
  messages: {
    en: {
      message: {
        hello: 'hello world',
        home_promo_title: 'Promó'
      }
    },
    hu: {
      message: {
        hello: 'Üdv',
        home_promo_title: 'Őszi készletkisöprés',
        home_promo_description: 'Tárazz be a kedvezményes ajánlatainkból!',
        home_promo_button: 'Tovább az akcióra'
      }
    }
  }
})

export default i18n