<template>
  <div v-if="isModalVisible" class="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:size-10">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="size-6 fill-yellow-600"><path d="M247.2 17c-22.1-3.1-44.6 .9-64.4 11.4l-74 39.5C89.1 78.4 73.2 94.9 63.4 115L26.7 190.6c-9.8 20.1-13 42.9-9.1 64.9l14.5 82.8c3.9 22.1 14.6 42.3 30.7 57.9l60.3 58.4c16.1 15.6 36.6 25.6 58.7 28.7l83 11.7c22.1 3.1 44.6-.9 64.4-11.4l74-39.5c19.7-10.5 35.6-27 45.4-47.2l36.7-75.5c9.8-20.1 13-42.9 9.1-64.9l-14.6-82.8c-3.9-22.1-14.6-42.3-30.7-57.9L388.9 57.5c-16.1-15.6-36.6-25.6-58.7-28.7L247.2 17zM208 144a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM144 336a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm224-64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
              </div>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-base font-semibold text-gray-900" id="modal-title">Süti (cookie) információk</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">Weboldalunk a működése során többféle saját, illetőleg külső szolgáltatóktól származó sütit és szolgáltatásokat használ annak érdekében, hogy a weboldal zavartalan működését, teljes funkcionalitását biztosítsa és magasfokú látogatói élményt garantáljon. Az igénybevételéhez szükségünk van az Ön hozzájárulására.</p>
                </div>
              </div>
            </div>
          </div>
          <form @submit.prevent="savePreferences">
            <div class="px-4 py-3 sm:px-6">

              <div class="sm:col-span-2 flex items-center mt-3">
                <input type="checkbox" v-model="cookieSettings.functional"  id="funkcionalis-cookie-consent" class="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-900" checked :disabled="true" />
                  <div class="ml-2">
                    <label for="funkcionalis-cookie-consent" class="text-sm font-medium text-gray-900">Funkcionális (kötelező)</label>
                  </div>
              </div>

              <div class="sm:col-span-2 flex items-center mt-3">
                <input type="checkbox" v-model="cookieSettings.comfort"  id="komfort-cookie-consent" class="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-900" checked />
                  <div class="ml-2">
                    <label for="komfort-cookie-consent" class="text-sm font-medium text-gray-900">Komfort</label>
                  </div>
              </div>

              <div class="sm:col-span-2 flex items-center mt-3">
                <input type="checkbox" v-model="cookieSettings.analytics"  id="statisztika-cookie-consent" class="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-900" checked />
                  <div class="ml-2">
                    <label for="statisztika-cookie-consent" class="text-sm font-medium text-gray-900">Statisztika</label>
                  </div>
              </div>

              <div class="sm:col-span-2 flex items-center mt-3">
                <input type="checkbox" v-model="cookieSettings.advertising"  id="marketing-cookie-consent" class="h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-900" checked />
                  <div class="ml-2">
                    <label for="marketing-cookie-consent" class="text-sm font-medium text-gray-900">Marketing</label>
                  </div>
              </div>
            </div>

            <div class="bg-gray-50 px-4 py-3 sm:px-6">
            
              <div class="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

                <button type="button" @click="rejectCookies" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                  Elutasítom
                </button>

                <button type="submit" class="inline-flex w-full justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 sm:col-start-2">
                  Elfogadom
                </button>
              </div>
            
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const isModalVisible = ref(true);
const cookieSettings = ref({
  functional: true,
  comfort: true,
  analytics: true,
  advertising: true,
});

onMounted(() => {
  const cookies = document.cookie.split(';');
  const cookieConsent = cookies.find(cookie => cookie.trim().startsWith('cookie_consent='));

  if (cookieConsent) {
    cookieSettings.value.comfort = cookies.find(cookie => cookie.trim().startsWith('comfort=')).split('=')[1] === 'true';
    cookieSettings.value.analytics = cookies.find(cookie => cookie.trim().startsWith('analytics=')).split('=')[1] === 'true';
    cookieSettings.value.advertising = cookies.find(cookie => cookie.trim().startsWith('advertising=')).split('=')[1] === 'true';
    isModalVisible.value = false;
  }
});

const savePreferences = () => {
  document.cookie = `cookie_consent=true; max-age=31536000; path=/`;
  document.cookie = `functional=${cookieSettings.value.functional}; max-age=31536000; path=/`;
  document.cookie = `comfort=${cookieSettings.value.comfort}; max-age=31536000; path=/`;
  document.cookie = `analytics=${cookieSettings.value.analytics}; max-age=31536000; path=/`;
  document.cookie = `advertising=${cookieSettings.value.advertising}; max-age=31536000; path=/`;

  if (window.gtag) {
    window.gtag('consent', 'update', {
      'ad_storage': cookieSettings.value.advertising ? 'granted' : 'denied',
      'analytics_storage': cookieSettings.value.analytics ? 'granted' : 'denied'
    });
  }

  isModalVisible.value = false;
};

const rejectCookies = () => {
  cookieSettings.value.comfort = false;
  cookieSettings.value.analytics = false;
  cookieSettings.value.advertising = false;
  savePreferences();
};
</script>
