import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/termek/:slug',
    name: 'ProductPage',
    component: () => import('@/views/ProductPage.vue'),
  },
  {
    path: '/rolunk',
    name: 'AboutPage',
    component: () => import('@/views/AboutPage.vue'),
  },
  {
    path: '/kapcsolat',
    name: 'ContactPage',
    component: () => import('@/views/ContactPage.vue'),
  },
  {
    path: '/gyakori-kerdesek',
    name: 'FAQPage',
    component: () => import('@/views/FAQPage.vue'),
  },
  {
    path: '/kategoria/:slug',
    name: 'CategoryPage',
    component: () => import('@/views/CategoryPage.vue'),
  },
  {
    path: '/kategoria/menu/:slug',
    name: 'SubmenuPage',
    component: () => import('@/views/SubmenuPage.vue'),
  },
  {
    path: '/blog/:id',
    name: 'BlogPage',
    component: () => import('@/views/BlogPage.vue'),
  },
  {
    path: '/gyarto/:slug',
    name: 'MerchantPage',
    component: () => import('@/views/MerchantPage.vue'),
  },
  {
    path: '/kosar',
    name: 'CartPage',
    component: () => import('@/views/CartPage.vue'),
  },
  {
    path: '/regisztracio',
    name: 'RegistrationPage',
    component: () => import('@/views/RegistrationPage.vue'),
  },
  {
    path: '/belepes',
    name: 'LoginPage',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/altalanos-szerzodesi-feltetelek',
    name: 'GeneralTermsPage',
    component: () => import('@/views/GeneralTermsPage.vue'),
  },
  {
    path: '/marketplace-altalanos-szerzodesi-feltetelek',
    name: 'MarketplaceGeneralTermsPage',
    component: () => import('@/views/MarketplaceGeneralTermsPage.vue'),
  },
  {
    path: '/adatkezelesi-tajekoztato',
    name: 'PrivacyPage',
    component: () => import('@/views/PrivacyPage.vue'),
  },
  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: () => import('@/views/CheckoutPage.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.cartItemCount > 0) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/thank-you',
    name: 'ThankyouPage',
    component: () => import('@/views/ThankyouPage.vue'),
  },
  {
    path: '/cache-clear',
    name: 'CacheClear',
    beforeEnter: (to, from, next) => {
      localStorage.clear();
      store.commit('clearCart');
      next('/');
    },
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: () => import('@/views/NotFoundPage.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (open.value) {
    open.value = false
  }
  next()
})

export default router;