<template>
  <div class="pt-24 sm:pt-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="text-center text-lg font-semibold leading-8 text-white">A HOMEINFO kiemelt partnerei</h2>
      <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-10 gap-y-10 sm:max-w-xl sm:grid-cols-6 lg:mx-0 lg:max-w-none lg:grid-cols-7">
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/arezzo-design.png" alt="Transistor" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/dublino-home.png" alt="Reform" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/elter.png" alt="Tuple" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/geberit.png" alt="SavvyCal" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/horanyi.png" alt="Statamic" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/kludi.png" alt="Statamic" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 sm:col-start-auto lg:col-span-1 w-full" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/lederland.png" alt="Statamic" /></a>
      </div>
      <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-10 gap-y-10 sm:max-w-xl sm:grid-cols-6 lg:mx-0 lg:max-w-none lg:grid-cols-7">
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/macryl.png" alt="Transistor" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/mofem.png" alt="Reform" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/natuzzi.png" alt="Tuple" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/radaway.png" alt="SavvyCal" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/ravak.png" alt="Statamic" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/sapho.png" alt="Statamic" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 sm:col-start-auto lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/spatrend.png" alt="Statamic" /></a>
      </div>
      <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-10 gap-y-10 sm:max-w-xl sm:grid-cols-6 lg:mx-0 lg:max-w-none lg:grid-cols-7">
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/strohm_teka.png" alt="Transistor" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/varrodesign.png" alt="Reform" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/vitalspa.png" alt="Tuple" /></a>
        <a href="#" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/wellis.png" alt="SavvyCal" /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportersList',
}
</script>