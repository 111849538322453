import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    products: [],
    appLogo: 'https://www.homeinfo.hu/assets/homeinfo2017/images/homeinfo-logo-124x54.tudastar.png',
    apiUrl: 'https://shopadmin.homeinfo.hu/api',
    appName: 'Homeinfo Shop',
    cart: {},
    selectCategory: {},
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    addToCart(state, product) {
      const vendorKey = product.vendor;
      if (!state.cart[vendorKey]) {
        state.cart[vendorKey] = [];
      }

      const existingProduct = state.cart[vendorKey].find(item => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        state.cart[vendorKey].push(product);
      }
    },
    removeFromCart(state, { vendor, productId }) {
      if (state.cart[vendor]) {
        state.cart[vendor] = state.cart[vendor].filter(item => item.id !== productId);
      }
    },
    updateCartQuantity(state, { vendor, productId, quantity }) {
      const product = state.cart[vendor]?.find(item => item.id === productId);
      if (product) {
        product.quantity = quantity;
      }
    },
    clearCart(state) {
      state.cart = {};
    },
    setSelectedCategory(state, child) {
      state.selectCategory = {
        id: child.id,
        name: child.name,
        slug: child.slug,
        category_id: child.category_id,
      };
    }
  },
  actions: {
    fetchProducts({ commit }) {
      const products = [/* termékek betöltése */];
      commit('setProducts', products);
    },
    addToCart({ commit }, product) {
      commit('addToCart', product);
    },
    removeFromCart({ commit }, productId) {
      commit('removeFromCart', productId);
    },
    setSelectedCategory({ commit }, child) {
      commit('setSelectedCategory', child);
    }
  },
  getters: {
    allProducts: state => state.products,
    apiUrl: state => state.apiUrl,
    appName: state => state.appName,
    appLogo: state => state.appLogo,
    cartItems: state => {
      return Object.values(state.cart || {}).flat();
    },
    cartItemCount: state => {
      if (!state.cart || Object.keys(state.cart).length === 0) return 0;

      return Object.values(state.cart).reduce((count, vendorItems) => {
        return count + vendorItems.reduce((vendorCount, item) => vendorCount + item.quantity, 0);
      }, 0);
    },
    selectCategory: (state) => state.selectCategory
  },
  plugins: [createPersistedState()],
});
