<template>
  <div id="app">
    <CookieConsentModal v-if="!hasAcceptedCookies" />
    <NavbarPage v-if="!isDisabledNavbar" />
    <router-view />
    <FooterPage />
  </div>
</template>

<script>
import NavbarPage from './components/Navbar.vue';
import FooterPage from './components/Footer.vue';
import CookieConsentModal from './components/CookieConsentModal.vue';

export default {
  name: 'App',
  components: {
    NavbarPage,
    FooterPage,
    CookieConsentModal,
  },
  data() {
    return {
      hasAcceptedCookies: false,
    };
  },
  computed: {
    isDisabledNavbar() {
      return this.$route.name === 'CheckoutPage' || this.$route.name === 'RegistrationPage' || this.$route.name === 'LoginPage';
    }
  },
  mounted() {
    const cookies = document.cookie.split(';');
    this.hasAcceptedCookies = cookies.some(cookie => cookie.trim().startsWith('cookie_consent=true'));
  },
};
</script>