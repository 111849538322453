<template>
  <TransitionRoot :show="localOpen" as="template" @after-leave="query = ''" appear>
    <Dialog class="relative" @close="localOpen = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-20 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox @update:modelValue="onSelect">
              <div class="relative">
                <MagnifyingGlassIcon class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Milyen terméket keresel?" @change="query = $event.target.value" @blur="query = ''" />
              </div>

              <ComboboxOptions v-if="query === '' || filteredProjects.length > 0" static as="ul" class="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto">
                <li class="p-2">
                  <h2 v-if="query === ''" class="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">Korábbi kereséseid</h2>
                  <ul class="text-sm text-gray-700">
                    <ComboboxOption v-for="project in query === '' ? recent : filteredProjects" :key="project.id" :value="project" as="template" v-slot="{ active }">
                      <li :class="['flex cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-gray-900 text-white']">
                        <FolderIcon :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400']" aria-hidden="true" />
                        <span class="ml-3 flex-auto truncate">{{ project.name }}</span>
                        <span v-if="active" class="ml-3 flex-none text-gray-700">Jump to...</span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
                <li v-if="query === ''" class="p-2">
                  <h2 class="sr-only">Quick actions</h2>
                  <ul class="text-sm text-gray-700">
                    <ComboboxOption v-for="action in quickActions" :key="action.shortcut" :value="action" as="template" v-slot="{ active }">
                      <li :class="['flex cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-gray-900 text-white']">
                        <component :is="action.icon" :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400']" aria-hidden="true" />
                        <span class="ml-3 flex-auto truncate">{{ action.name }}</span>
                        <span :class="['ml-3 flex-none text-xs font-semibold', active ? 'text-gray-700' : 'text-gray-400']">
                          <kbd class="font-sans">⌘</kbd>
                          <kbd class="font-sans">{{ action.shortcut }}</kbd>
                        </span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>

              <div v-if="query !== '' && filteredProjects.length === 0" class="px-6 py-14 text-center sm:px-14">
                <FolderIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 text-sm text-gray-900">We couldn't find any projects with that term. Please try again.</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, defineProps, ref, watch } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { DocumentPlusIcon, FolderIcon, FolderPlusIcon, HashtagIcon, TagIcon } from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

const projects = [
  { id: 1, name: 'Workflow Inc. / Website Redesign', url: '#' },
]
const recent = [projects[0]]
const quickActions = [
  { name: 'Add new file...', icon: DocumentPlusIcon, shortcut: 'N', url: '#' },
  { name: 'Add new folder...', icon: FolderPlusIcon, shortcut: 'F', url: '#' },
  { name: 'Add hashtag...', icon: HashtagIcon, shortcut: 'H', url: '#' },
  { name: 'Add label...', icon: TagIcon, shortcut: 'L', url: '#' },
]

const props = defineProps({
  open: Boolean
})

const localOpen = ref(props.open)

watch(() => props.open, (newValue) => {
  if (newValue) {
    localOpen.value = true;
  } else {
    localOpen.value = false;
  }
})

const query = ref('')
const filteredProjects = computed(() =>
  query.value === ''
    ? []
    : projects.filter((project) => {
        return project.name.toLowerCase().includes(query.value.toLowerCase())
      }),
)

function onSelect(item) {
  if (item) {
    window.location = item.url
  }
}
</script>

<script>
export default {
  name: 'SearchModal',
}
</script>